import {
  ChangeDetectorRef,
  Component,
  computed,
  Input,
  OnInit,
  signal,
} from "@angular/core";
import { TranscriptService } from "../../../../core-ui/services/transcript.service";
import {
  AgentParam,
  AppConfigService,
  createFileAndDownload,
  extractErrorMessage,
  ITranscriptStatus,
  ITranscriptTransformStatus,
  ITranscriptTranslateStatus,
  NotificationService,
  ProviderLanguage,
  ProviderOrganizationLanguage,
  TranscriptStateEnum,
} from "../../../../core-ui";
import { Subscription } from "rxjs";

@Component({
  selector: "app-composition-transcript",
  templateUrl: "./composition-transcript.component.html",
  styleUrls: ["./composition-transcript.component.scss"],
})
export class CompositionTranscriptComponent implements OnInit {
  @Input() conversationId: string;

  subscriptions: Subscription = new Subscription();
  languageMap: { [code: string]: string } = {};
  languages = signal<ProviderOrganizationLanguage[]>([]);
  providerLanguages = signal<ProviderLanguage[]>([]);
  translationLanguages = signal<ProviderLanguage[]>([]);
  activeLanguage: ProviderOrganizationLanguage = null;
  translateLanguage: ProviderOrganizationLanguage = null;
  promptText: string;
  transcriptStatus = signal<ITranscriptStatus>(null);
  isTranscriptLoading = signal(false);
  isTranslateLoading = signal(false);
  isSentimentLoading = signal(false);
  isSummaryLoading = signal(false);
  isPromptLoading = signal(false);
  isLoadingURL = signal(false);
  isTranslatePopupOpen = signal(false);
  isSummarizeAvailable = false;
  isTranslateAvailable = false;
  isSentimentAvailable = false;
  isPromptAvailable = false;
  confirmRemove = signal(false);
  transcriptUrl = signal<string>(undefined);
  transformSummary = signal<ITranscriptTransformStatus>(undefined);
  transformTranslation = signal<ITranscriptTranslateStatus>(undefined);
  transformSentiment = signal<ITranscriptTransformStatus>(undefined);
  transformPrompt = signal<ITranscriptTransformStatus>(undefined);
  lang: string;
  canRequestFormattedTranscript = false;

  constructor(
    private transcript: TranscriptService,
    private notification: NotificationService,
    private config: AppConfigService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.lang = this.config.language;
    this.canRequestFormattedTranscript =
      this.transcript.isDownloadFormattedTranscriptSupported();

    this.getLanguages();
    this.getTranscriptStatus();

    this.isSummarizeAvailable = this.transcript.isFeatureSupported("summary");
    this.isTranslateAvailable =
      this.transcript.isFeatureSupported("translation");
    this.isSentimentAvailable = this.transcript.isFeatureSupported("sentiment");
    this.isPromptAvailable = this.transcript.isFeatureSupported("prompt");

    this.subscriptions.add(
      this.transcript.eventReceived$.subscribe(async (e) => {
        switch (e.type) {
          case "ASROfflineTranscriptCreatedEvent":
          case "ASROfflineTranscriptProcessingEvent":
            this.getTranscriptStatus();
            break;
          case "ASROfflineTranscriptFailedEvent":
            this.notification.error(
              "Could not create transcript. Please try again"
            );
            this.transcriptStatus.set(undefined);
            break;
          case "ASROfflineTranslationCreatedEvent":
          case "ASROfflineTranslationProcessingEvent":
          case "ASROfflineTranslationFailedEvent":
            const trans = await this.getTranslation(e.id);
            this.transformTranslation.set(trans);
            break;
          case "AIPromptProcessingEvent":
          case "AIPromptFailedEvent":
          case "AIPromptCreatedEvent":
            const tr = await this.getTransform(e.id);
            switch (tr.intent) {
              case "sentiment":
                this.transformSentiment.set(tr);
                break;
              case "summary":
                this.transformSummary.set(tr);
                break;
              case "prompt":
                this.transformPrompt.set(tr);
                break;
            }
            break;
        }
      })
    );
  }

  private async getLanguages() {
    try {
      const [providerLanguages, configuredLanguages, translationLanguages] =
        await Promise.all([
          this.transcript.getProviderLanguages("transcriptions", 0, 20),
          this.transcript.getOrganizationLanguages(0, 20),
          this.transcript.getProviderLanguages("translations", 0, 20),
        ]);

      this.providerLanguages.set(
        providerLanguages.content.map((l) => new ProviderLanguage(l))
      );
      this.translationLanguages.set(
        translationLanguages.content.map((l) => new ProviderLanguage(l))
      );

      this.providerLanguages().forEach(
        (l) => (this.languageMap[l.code] = l.name)
      );

      // provisioned
      this.languages.set(
        configuredLanguages.content.map(
          (l) =>
            new ProviderOrganizationLanguage({
              ...l,
              languageName: this.languageMap[l.languageCode],
            })
        )
      );

      this.cd.detectChanges();
    } catch (ex) {
      // this.notification.error(extractErrorMessage(ex));
    }
  }

  async removeTranscript() {
    try {
      this.confirmRemove.set(false);
      const id = this.transcriptStatus().id;
      this.transcriptStatus.set(undefined);
      await this.transcript.removeTranscript(this.conversationId, id);
    } catch (ex) {
      this.notification.error("Could not remove existing transcript", {
        body: extractErrorMessage(ex),
      });
      this.requestTranscript();
    }
  }

  async requestTranscript() {
    try {
      this.isTranscriptLoading.set(true);
      const response = await this.transcript.createTranscriptRequest(
        this.conversationId,
        this.activeLanguage.id
      );
      this.transcriptStatus.set({
        id: response.id,
        state: TranscriptStateEnum.submitted,
        createdAt: new Date(),
        language: this.activeLanguage.id,
      });
      this.activeLanguage = null;
      this.transcriptUrl.set(null);
      this.getTranscriptStatus();
    } catch (ex) {
      this.notification.error("Could not request a transcript", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isTranscriptLoading.set(false);
    }
  }

  private async getTransforms() {
    try {
      const [transforms, translations] = await Promise.all([
        this.transcript
          .getTransformsForConversation(
            this.conversationId,
            this.transcriptStatus().id
          )
          .catch((_) => []),
        this.transcript
          .getTranslations(this.conversationId, this.transcriptStatus().id)
          .catch((e) => []),
      ]);

      this.transformSummary.set(transforms.find((t) => t.intent === "summary"));
      this.transformTranslation.set(translations?.[0]);
      this.transformSentiment.set(
        transforms.find((t) => t.intent === "sentiment")
      );
      this.transformPrompt.set(transforms.find((t) => t.intent === "prompt"));
    } catch (ex) {
      // nothing
    }
  }

  private async getTransform(id: string) {
    try {
      const response = await this.transcript.getTransformStatusForConversation(
        this.conversationId,
        this.transcriptStatus().id,
        id
      );
      return response;
    } catch (ex) {
      return undefined;
    }
  }

  private async getTranslation(id: string) {
    return this.transcript.getTranslation(
      this.conversationId,
      this.transcriptStatus().id,
      id
    );
  }

  private async getTranscriptStatus() {
    try {
      this.isTranscriptLoading.set(true);
      const transcripts = await this.transcript.getTranscriptsForConversation(
        this.conversationId
      );
      this.transcriptStatus.set(transcripts?.[0]);

      if (
        !this.transcriptStatus() ||
        this.transcriptStatus()?.state.toLowerCase() ===
          TranscriptStateEnum.failed.toLowerCase()
      ) {
        return this.transcriptStatus.set(undefined);
      }
      if (
        this.transcriptStatus().state.toLowerCase() ===
        TranscriptStateEnum.completed.toLowerCase()
      ) {
        this.getTransforms();
      }
    } catch (ex) {
      this.transcriptStatus.set(undefined);
    } finally {
      this.isTranscriptLoading.set(false);
    }
  }

  async getDownloadLink() {
    try {
      this.isLoadingURL.set(true);
      const response = await this.transcript.getTranscriptURL(
        this.conversationId,
        this.transcriptStatus().id,
        "inline"
      );
      this.transcriptUrl.set(response.url);
    } catch (ex) {
      this.notification.error("Could not generate download URL", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isLoadingURL.set(false);
    }
  }

  async download() {
    try {
      const response = await this.transcript.getTranscript(
        this.conversationId,
        this.transcriptStatus().id
      );
      createFileAndDownload(
        JSON.stringify(response),
        "transcript.json",
        "text/plain"
      );
    } catch (ex) {
      this.notification.error("Could not download transcript", {
        body: extractErrorMessage(ex),
      });
    }
  }

  async summarize() {
    try {
      this.isSummaryLoading.set(true);
      const res = await this.transcript.transformTranscriptForConversation(
        this.conversationId,
        this.transcriptStatus().id,
        { intent: "summary" }
      );
      this.transformSummary.set({
        id: res.id,
        intent: "summary",
        state: TranscriptStateEnum.submitted,
        transcript: this.transcriptStatus().id,
        createdAt: new Date(),
      });
    } catch (ex) {
      this.notification.error("Could not summarize transcript", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isSummaryLoading.set(false);
    }
  }

  async translate() {
    try {
      this.isTranslatePopupOpen.set(false);
      this.isTranslateLoading.set(true);
      const res = await this.transcript.translate(
        this.conversationId,
        this.transcriptStatus().id,
        { language: this.translateLanguage.code }
      );
      this.transformTranslation.set({
        id: res.id,
        state: TranscriptStateEnum.submitted,
        language: this.translateLanguage.code,
        transcript: this.transcriptStatus().id,
        createdAt: new Date(),
      });
    } catch (ex) {
      this.notification.error("Could not translate transcript", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isTranslateLoading.set(false);
    }
  }

  async analyze() {
    try {
      this.isSentimentLoading.set(true);
      const res = await this.transcript.transformTranscriptForConversation(
        this.conversationId,
        this.transcriptStatus().id,
        { intent: "sentiment" }
      );
      this.transformSentiment.set({
        id: res.id,
        intent: "sentiment",
        state: TranscriptStateEnum.submitted,
        transcript: this.transcriptStatus().id,
        createdAt: new Date(),
      });
    } catch (ex) {
      this.notification.error("Could not analyze transcript", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isSentimentLoading.set(false);
    }
  }

  async prompt() {
    try {
      this.isPromptLoading.set(true);
      const res = await this.transcript.transformTranscriptForConversation(
        this.conversationId,
        this.transcriptStatus().id,
        {
          intent: "prompt",
          customPrompt: this.config.agentParam(AgentParam.AI_GENERIC_PROMPT),
        }
      );
      this.transformPrompt.set({
        id: res.id,
        intent: "prompt",
        state: TranscriptStateEnum.submitted,
        transcript: this.transcriptStatus().id,
        createdAt: new Date(),
      });
    } catch (ex) {
      this.notification.error("Could not analyze transcript", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isPromptLoading.set(false);
    }
  }

  /** getters */

  get transcriptTitle() {
    return computed(
      () =>
        this.providerLanguages().find(
          (l) => l.code === this.transcriptStatus().language
        )?.name
    );
  }

  get isTranscriptAvailable() {
    return computed(() => !!this.transcriptStatus());
  }

  get isTranscriptProcessing() {
    return computed(
      () =>
        this.transcriptStatus()?.state.toLowerCase() ===
          TranscriptStateEnum.processing.toLowerCase() ||
        this.transcriptStatus()?.state.toLowerCase() ===
          TranscriptStateEnum.resubmit.toLowerCase()
    );
  }

  get isTranscriptCompleted() {
    return computed(
      () =>
        this.transcriptStatus()?.state.toLowerCase() ===
        TranscriptStateEnum.completed.toLowerCase()
    );
  }

  get isTranscriptSubmitted() {
    return computed(
      () =>
        this.transcriptStatus()?.state.toLowerCase() ===
        TranscriptStateEnum.submitted.toLowerCase()
    );
  }

  get isGenerateTranscriptDisabled() {
    return computed(
      () =>
        !this.activeLanguage ||
        this.isTranscriptLoading() ||
        this.isTranscriptProcessing()
    );
  }

  get isDeleteTranscriptDisabled() {
    return computed(
      () =>
        this.isTranscriptLoading() ||
        this.isTranscriptSubmitted() ||
        this.isTranscriptProcessing()
    );
  }
}
