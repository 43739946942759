import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  computed,
  signal,
} from "@angular/core";
import { IEndpoint, StreamTypes } from "@auvious/rtc";
import { ISnapshot } from "@auvious/snapshot";
import { merge, Subscription, map } from "rxjs";
import { filter } from "rxjs/operators";
import {
  AgentParam,
  IInteraction,
  CustomerParam,
  SpeechToTextMetadata,
} from "../../models";
import {
  SNAPSHOT_VISION_TYPE,
  StreamTrackKindEnum,
  UserRoleEnum,
} from "../../core-ui.enums";
import { TranscriptService } from "../../services/transcript.service";
import { TileComponent } from "../tile/tile.component";
import {
  AppConfigService,
  AuviousRtcService,
  ConferenceService,
  ConferenceStore,
  isDebugRtcOn,
  PointerService,
  SnapshotService,
  StreamState,
  UserService,
} from "../../services";
@Component({
  selector: "app-tile-info",
  templateUrl: "./tile-info.component.html",
  styleUrls: ["./tile-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileInfoComponent implements OnInit, OnDestroy {
  @Input()
  set stream(value: StreamState) {
    this._stream = value;

    this.isMutedMicAria.set(
      this.conference.isMutedStreamId(
        this._stream.id,
        StreamTrackKindEnum.audio
      )
    );

    this.isMutedMic.set(
      this.conference.isMutedStreamId(
        this._stream.id,
        StreamTrackKindEnum.audio
      ) &&
        !this.conference.isMutedStreamId(
          this._stream.id,
          StreamTrackKindEnum.video
        )
    );

    this.isMutedCam.set(
      this.conference.isMutedStreamId(
        this._stream.id,
        StreamTrackKindEnum.video
      )
    );
  }
  get stream() {
    return this._stream;
  }
  @Input() interaction: IInteraction;

  isAgent = false;
  isCustomer = false;
  subscription: Subscription;
  snapshots: ISnapshot[] = [];
  snapshotCount = 0;
  isCaptionsOn = false;
  isTileTooSmall = false;
  _stream: StreamState;

  isMutedMic = signal(false);
  isMutedMicAria = signal(false);
  isMutedCam = signal(false);
  color = signal(undefined);

  constructor(
    private rtc: AuviousRtcService,
    private config: AppConfigService,
    private conference: ConferenceService,
    private snapshot: SnapshotService,
    private cdr: ChangeDetectorRef,
    private pointer: PointerService,
    private asr: TranscriptService,
    private pointerService: PointerService,
    private store: ConferenceStore,
    private renderer: Renderer2,
    userService: UserService,
    @Optional() private tile: TileComponent
  ) {
    this.isAgent = userService.isAgent;
    this.isCustomer = userService.isCustomer;
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.snapshot.snapshotsAvailable$
        .pipe(map((a) => a.filter((s) => s.type !== SNAPSHOT_VISION_TYPE)))
        .subscribe((snapshots) => {
          this.snapshots = snapshots;
          this.snapshotCount = snapshots.filter(
            (s) => s.userId === this.stream.originator.username
          ).length;
          this.cdr.detectChanges();
        })
    );
    this.subscription.add(
      this.pointer.pointColorsChange$.subscribe((colors) => {
        const color = colors[this.stream.originator.endpoint];
        this.color.set(color);
      })
    );
    this.subscription.add(
      merge(
        this.conference.conferenceMetadataSet$.pipe(
          filter((m) => m instanceof SpeechToTextMetadata)
        ),
        this.asr.enabled$
      ).subscribe((_) => {
        this.isCaptionsOn = true;
        this.cdr.detectChanges();
      })
    );
    this.subscription.add(
      merge(
        this.conference.conferenceMetadataRemoved$.pipe(
          filter((m) => m instanceof SpeechToTextMetadata)
        ),
        this.asr.disabled$
      ).subscribe((_) => {
        this.isCaptionsOn = false;
        this.cdr.detectChanges();
      })
    );
    if (this.tile) {
      this.subscription.add(
        this.tile.viewSizeChanged.subscribe((size) => {
          this.isTileTooSmall = size.width < 180 || size.height < 180;
          this.cdr.detectChanges();
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  snapshotRequest() {
    if (
      !this.snapshot.isStarted &&
      !this.pointer.isStarted &&
      !this.store.screenStream &&
      !this.isWhisper
    ) {
      this.snapshot.request(this.stream.id);
    }
  }

  get isHidden() {
    return (
      this.pointerService.isStarted &&
      !this.pointerService.isTarget(
        this.stream.originator.endpoint,
        this.stream.type,
        this.stream.correlationId
      ) &&
      this.isTileTooSmall
    );
  }

  get isWhisper() {
    return !!this.interaction.getWhisperMode();
  }

  get participantLanguage(): string {
    const participants = this.conference.getParticipants();
    const participant = participants?.[this.stream.originator.endpoint];
    return participant?.metadata?.language;
  }

  get isScreen() {
    return this.stream?.type === StreamTypes.SCREEN;
  }

  get isRoleAgent() {
    const participants = this.conference.getParticipants();
    const participant = participants?.[this.stream.originator.endpoint];
    return participant?.metadata?.roles?.includes(UserRoleEnum.agent);
  }

  get isDebugRtc() {
    return isDebugRtcOn();
  }

  get myself(): IEndpoint {
    return this.rtc.myself;
  }

  get isLocal() {
    return this.stream.originator.endpoint === this.myself.endpoint;
  }

  get isPointerActive() {
    return this.pointer.isStarted;
  }

  get isNetworkIndicatorVisible() {
    return this.isAgent && !this.isLocal;
  }

  get isRoleAgentVisible() {
    return !this.isLocal && this.isRoleAgent;
  }

  get isParticipantLanguageEnabled() {
    return (
      this.isAgent &&
      !this.isLocal &&
      this.config.agentParamEnabled(AgentParam.CUSTOMER_LANGUAGE)
    );
  }

  // get snapshotCount() {
  //   const count = this.snapshots.filter(
  //     (s) => s.userId === this.stream.originator.username
  //   ).length;
  //   return count;
  // }

  get isParticipantControlsAvailable() {
    return (
      (this.isCustomer &&
        this.config.customerParamEnabled(
          CustomerParam.PARTICIPANT_CONTROLS_ENABLED
        )) ||
      !this.isCustomer
    );
  }

  get isGroupOneAvailable() {
    return computed(
      () =>
        this.isDebugRtc ||
        (!this.isScreen &&
          ((this.isRoleAgentVisible && !this.isHidden) ||
            (this.isNetworkIndicatorVisible && !this.isHidden) ||
            this.isMutedMic() ||
            (this.isCaptionsOn && !this.isHidden) ||
            (this.color && this.isPointerActive) ||
            (this.isParticipantLanguageEnabled && !this.isHidden) ||
            this.isViewerDisabled))
    );
  }

  public get isViewerDisabled() {
    return this.stream.video.disabledFromBitrateAdaptation;
  }
}
