import {
  Component,
  signal,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  AppConfigService,
  createFileAndDownload,
  extractErrorMessage,
  ITranscriptTransformStatus,
  NotificationService,
  TranscriptStateEnum,
  TranscriptTransformType,
} from "../../../../core-ui";
import { TranscriptService } from "../../../../core-ui/services/transcript.service";

@Component({
  selector: "app-composition-transcript-transform",
  templateUrl: "./composition-transcript-transform.component.html",
  styleUrls: ["./composition-transcript-transform.component.scss"],
})
export class CompositionTranscriptTransformComponent implements OnInit {
  @Input() conversationId: string;
  @Input() transcriptId: string;
  @Input() transform: ITranscriptTransformStatus;
  @Input() transformType: TranscriptTransformType;

  @Output() removed = new EventEmitter<void>();

  url = signal<string>(null);
  confirmRemove = signal(false);
  title: string;
  subtitle: string;
  icon: string;
  isLoadingURL = signal(false);
  isRemoveLoading = signal(false);
  canRequestFormattedTranscript = false;

  constructor(
    private transcript: TranscriptService,
    private notification: NotificationService,
    private config: AppConfigService
  ) {}

  ngOnInit(): void {
    switch (this.transformType) {
      case "sentiment":
        this.icon = "file-sentiment";
        this.title = "Sentiment Analysis";
        this.subtitle = "Positive, negative or neutral";
        break;
      case "summary":
        this.icon = "file-summary";
        this.title = "Summary";
        this.subtitle = "Basic bullet points of the conversation";
        break;
      case "translation":
        this.icon = "file-translation";
        this.title = "Translation";
        this.subtitle = "English";
        break;
      case "prompt":
        this.icon = "file-prompt";
        this.title = "Prompt";
        this.subtitle = "Custom prompt";
    }
    this.canRequestFormattedTranscript =
      this.transcript.isDownloadFormattedTranscriptSupported(
        this.transformType
      );
  }

  async getURL() {
    try {
      this.isLoadingURL.set(true);
      const data =
        this.transformType === "translation"
          ? await this.transcript.getTranslationURL(
              this.conversationId,
              this.transcriptId,
              this.transform.id
            )
          : await this.transcript.getTransformURL(
              this.conversationId,
              this.transcriptId,
              this.transform.id
            );
      this.url.set(data.url);
    } catch (ex) {
      this.notification.error("Could not retrieve URL", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isLoadingURL.set(false);
    }
  }

  async removeTransform() {
    try {
      this.isRemoveLoading.set(true);
      this.confirmRemove.set(false);
      this.transformType === "translation"
        ? await this.transcript.removeTranslation(
            this.conversationId,
            this.transcriptId,
            this.transform.id
          )
        : await this.transcript.removeTransform(
            this.conversationId,
            this.transcriptId,
            this.transform.id
          );
      this.removed.emit();
    } catch (ex) {
      this.notification.error("Could not remove file", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isRemoveLoading.set(false);
    }
  }

  async download() {
    try {
      const response =
        this.transformType === "translation"
          ? await this.transcript.getTranslationContent(
              this.conversationId,
              this.transcriptId,
              this.transform.id
            )
          : await this.transcript.getTransformContent(
              this.conversationId,
              this.transcriptId,
              this.transform.id
            );
      createFileAndDownload(
        JSON.stringify(response),
        `${this.transformType}.json`,
        "text/plain"
      );
    } catch (ex) {
      this.notification.error("Could not download transcript", {
        body: extractErrorMessage(ex),
      });
    }
  }

  get isCompleted() {
    return (
      this.transform.state.toLowerCase() ===
      TranscriptStateEnum.completed.toLowerCase()
    );
  }

  get isProcessing() {
    return (
      this.transform.state.toLowerCase() ===
      TranscriptStateEnum.processing.toLowerCase()
    );
  }
}
