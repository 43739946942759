import { Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
  BrowserNotificationStrategy,
  NoAnalyticsStrategy,
  NoIntegrationStrategy,
  NoQueueStrategy,
  DefaultInteractionStrategy,
  StandaloneInvitationStrategy,
  NoConversationStrategy,
} from "../strategies";

import {
  OAuth2CodeGrantAuthenticationStrategy,
  IInteractionStrategy,
  IAnalyticsStrategy,
  IAuthenticationStrategy,
  IIntegrationStrategy,
  IQueueStrategy,
  IInvitationStrategy,
  INotificationStrategy,
  IConversationStrategy,
} from "../../../core-ui/models/strategies";
import {
  AuviousRtcService,
  AppConfigService,
  UserService,
  ProtectedTicketService,
} from "../../../core-ui/services";
import {
  IApplication,
  IApplicationDTO,
  IOpenIDConnectApplicationConfig,
} from "../../../core-ui/models/IApplication";
import {
  ApplicationTypeEnum,
  MediaFilterTypeEnum,
} from "../../../core-ui/core-ui.enums";
import { of } from "rxjs";
import { OAUTH2_CODE_GRAND_REDIRECT_URI } from "../../../core-ui/core-ui.enums";
export class OpenIDConnectApplication implements IApplication {
  private mAuthenticationStrategy: IAuthenticationStrategy;
  private mInteractionStrategy: IInteractionStrategy;
  private mAnalyticsStrategy: IAnalyticsStrategy;
  private mQueueStrategy: IQueueStrategy;
  private mTicketStrategy: IInvitationStrategy;
  private mNotificationStrategy: INotificationStrategy;
  private mIntegrationStrategy: IIntegrationStrategy;
  private mConversationStrategy: IConversationStrategy;

  private appConfigService: AppConfigService;
  private userService: UserService;

  constructor(private dto: IApplicationDTO, private injector: Injector) {
    this.appConfigService = injector.get(AppConfigService);
    this.appConfigService.setApplicationConfig(dto.config);
    this.userService = injector.get(UserService);

    this.mAuthenticationStrategy = new OAuth2CodeGrantAuthenticationStrategy(
      this.injector.get(AuviousRtcService),
      this.injector.get(HttpClient),
      {
        clientId: "openid",
        redirectUri: window.location.origin + OAUTH2_CODE_GRAND_REDIRECT_URI,
        authProvider: async () => {
          const config = this.dto.config as IOpenIDConnectApplicationConfig;
          return {
            clientId: config.openidParameters["client-id"],
            authorizationUrl: config.openidParameters["authorization-url"],
            authorizationUrlExtraParameters:
              config.openidParameters["authorization-url-extra-parameters"],
            scope: config.openidParameters.scope || "openid",
          };
        },
        hydratePasswordParams: (params) => {
          params.set("application_id", this.getId());
        },
        config: dto.config,
      }
    );

    this.mInteractionStrategy = new DefaultInteractionStrategy(
      injector.get(ProtectedTicketService)
    );
    this.mAnalyticsStrategy = new NoAnalyticsStrategy();
    this.mQueueStrategy = new NoQueueStrategy();
    this.mTicketStrategy = new StandaloneInvitationStrategy(
      this.getId(),
      this.getConfig()?.publicParameters,
      this.getConfig()?.agentParameters
    );
    this.mNotificationStrategy = BrowserNotificationStrategy.singleton();
    this.mIntegrationStrategy = new NoIntegrationStrategy();
    this.mConversationStrategy = new NoConversationStrategy();
  }

  supportsInteractions(): boolean {
    return false;
  }

  supportsInteractionMetrics(): boolean {
    return false;
  }

  getId(): string {
    return this.dto.id;
  }

  registerLifecycleEventHandlers() {}

  getDto(): IApplicationDTO {
    return this.dto;
  }

  getType(): ApplicationTypeEnum {
    return ApplicationTypeEnum.StandaloneOpenID;
  }

  getOrganizationId(): string {
    return this.dto.organizationId;
  }

  getConfig(): IOpenIDConnectApplicationConfig {
    return this.dto.config;
  }

  getCreatedAt(): Date {
    return new Date(this.dto.createdAt);
  }

  getVersion(): number {
    return this.dto.version;
  }

  getFilterBackgrounds() {
    const url = "core-ui/assets/media-filter-backgrounds";
    return [
      {
        id: "open-id-1",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/living-room-1.jpg`),
      },
      {
        id: "open-id-2",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/living-room-4.jpg`),
      },
      {
        id: "open-id-3",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/office-1.jpg`),
      },
    ];
  }

  canCallWithoutActiveInteraction(): boolean {
    return true;
  }

  isReady(): boolean {
    return true;
  }

  authenticationStrategy(): IAuthenticationStrategy {
    return this.mAuthenticationStrategy;
  }

  interactionStrategy(): IInteractionStrategy {
    return this.mInteractionStrategy;
  }

  queueStrategy(): IQueueStrategy {
    return this.mQueueStrategy;
  }

  analyticsStrategy(): IAnalyticsStrategy {
    return this.mAnalyticsStrategy;
  }

  ticketStrategy(): IInvitationStrategy {
    return this.mTicketStrategy;
  }

  notificationStrategy(): INotificationStrategy {
    return this.mNotificationStrategy;
  }

  integrationStrategy(): IIntegrationStrategy {
    return this.mIntegrationStrategy;
  }

  conversationStrategy(): IConversationStrategy {
    return this.mConversationStrategy;
  }
}
