import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from "@angular/core";
import { ConferenceMode, IEndpoint } from "@auvious/rtc";
import { NotificationService } from "../../../core-ui/services/notification.service";
import { Interaction } from "../../models/Interaction";
import { InteractionService, InvitationService } from "../../services";
import {
  AppConfigService,
  ApplicationService,
  AuviousRtcService,
  copyTextToClipboard,
  debugError,
  ProtectedTicketService,
  GenericErrorHandler,
} from "../../../core-ui/services";
import { TranslateService } from "@ngx-translate/core";
import {
  ConversationOriginEnum,
  EndpointTypeEnum,
} from "../../../core-ui/core-ui.enums";
import { Subscription } from "rxjs";
import { CobrowseService } from "../../../core-ui/services/cobrowse.service";
import { AgentParam, IInteraction } from "../../../core-ui";
import { AnalyticsService } from "../../../core-ui/services/analytics.service";
import { TicketTypeEnum } from "../../../core-ui/models/ITicket";
import {
  CobrowseRequestType,
  ConversationTypeEnum,
} from "@auvious/integrations";
import { Util } from "@auvious/common";

@Component({
  selector: "app-cobrowse-launcher",
  templateUrl: "./cobrowse-launcher.component.html",
  styleUrls: ["./cobrowse-launcher.component.scss"],
})
export class CobrowseLauncherComponent implements OnInit, OnDestroy {
  @Input()
  set interaction(interaction: IInteraction) {
    if (!interaction) {
      return;
    }
    if (
      [
        ConversationTypeEnum.callback,
        ConversationTypeEnum.videoCall,
        ConversationTypeEnum.voiceCall,
      ].includes(interaction.getType())
    ) {
      try {
        this.cobrowseService.terminate();
      } catch (ex) {
        debugError(ex);
        this.closed.emit();
      }
    }
  }

  @Input() requestType: CobrowseRequestType;

  @Output() started: EventEmitter<{
    participant: IEndpoint;
    interaction: IInteraction;
  }> = new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter();

  ticketId: string;
  subscriptions: Subscription;
  connecting = false;
  connectTimeout;
  isCobrowse = false;
  isDisplayCapture = false;
  canGenerateChatInvitation = false;
  endpointType: EndpointTypeEnum;

  constructor(
    private interactionService: InteractionService,
    private rtcService: AuviousRtcService,
    private invitationService: InvitationService,
    private ticketService: ProtectedTicketService,
    private notification: NotificationService,
    private translate: TranslateService,
    private cobrowseService: CobrowseService,
    private errorHandler: GenericErrorHandler,
    private analytics: AnalyticsService,
    private config: AppConfigService
  ) {}

  ngOnInit(): void {
    this.isCobrowse = this.requestType === "view";
    this.isDisplayCapture = this.requestType === "display-capture";

    this.endpointType = this.isCobrowse
      ? EndpointTypeEnum.coBrowse
      : EndpointTypeEnum.displayCapture;

    // support flow to send a co-browse invitation to an existing chat session even if auvious does not know about active interactions
    this.canGenerateChatInvitation = this.config.agentParamEnabled(
      AgentParam.COBROWSE_MANUAL_CHAT_INVITATION_ENABLED
    );

    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.cobrowseService.sessionEnded$.subscribe((_) => {
        // clear room, in case we want to start a new cobrowse
        this.resetRoom();
        this.closed.emit();
      })
    );

    this.connect();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async connect() {
    try {
      let interaction = this.interactionService.getActiveInteraction();
      let conference = null;

      if (!interaction) {
        conference = await this.rtcService.createConference({
          mode: ConferenceMode.ROUTER,
          interactionId: Util.uuidgen(),
        });
        interaction = new Interaction(conference.name);
        interaction.setType(ConversationTypeEnum.cobrowse);
        interaction.setOrigin(ConversationOriginEnum.WIDGET);
        this.interactionService.setActiveInteraction(interaction);
      }

      if (!interaction.hasRoom()) {
        // always create a new room
        conference = await this.rtcService.createConference({
          mode: ConferenceMode.ROUTER,
          interactionId: interaction.getId(),
        });
        interaction.setRoom(conference.name);
        this.interactionService.setActiveInteraction(interaction);
        this.analytics.trackInvitationSent(interaction);
      }

      const ticketRequest = this.invitationService.prepareTicketRequest(
        TicketTypeEnum.SingleUseTicket,
        6,
        interaction.getRoom(),
        interaction.getCustomerId() || "cobrowsing_customer",
        interaction.getId()
      );
      this.ticketId = await this.ticketService.createTicket(ticketRequest);

      this.interactionService.setActiveInteraction(interaction);

      await this.cobrowseService.join(interaction.getRoom(), this.endpointType);

      this.subscriptions.add(
        this.cobrowseService.participantJoined$.subscribe(async (p) => {
          if (!p) {
            return;
          }

          if (this.connectTimeout) {
            clearTimeout(this.connectTimeout);
            this.connectTimeout = undefined;
          }

          interaction =
            this.interactionService.getActiveInteraction() ?? interaction;

          // todo: we will need to start a dummy conversation just to keep the agent as busy while cobrowsing.
          await this.analytics.trackCallRequested(interaction, {
            persistOnIntegration: false,
          });
          await this.analytics.trackCobrowseRequested(interaction, p);

          this.started.emit({ participant: p, interaction });
        })
      );

      this.focusCopyBtn();
    } catch (ex) {
      this.notification.error("Error", {
        body: ex.body?.message || ex.message || ex,
      });
      debugError(ex);
      this.errorHandler.handleError(ex);
    }
  }

  private focusCopyBtn() {
    requestAnimationFrame(() => {
      const copyPinElm = document.querySelector(
        "[data-tid='cobrowse/copy-pin']"
      ) as HTMLButtonElement;

      copyPinElm?.focus();
    });
  }

  close() {
    if (this.connecting) {
      return;
    }
    try {
      this.cobrowseService.terminate();
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
    this.resetRoom();
    this.closed.emit();
  }

  async resetRoom() {
    const interaction = this.interactionService.getActiveInteraction();
    if (!!interaction) {
      interaction.setRoom(null);
      this.interactionService.setActiveInteraction(interaction);
      try {
        await this.analytics.trackConferenceRoomEnded(interaction);
      } catch (ex) {}
    }
  }

  copy() {
    copyTextToClipboard(this.ticketId);
    this.notification.success("PIN", { body: "Copied to clipboard" });
    this.focusCopyBtn();
  }

  invite() {
    const interaction = this.interactionService.getActiveInteraction();
    this.notification.show("Auvious", {
      body: this.translate.instant("Invitation has been sent."),
    });
    this.interactionService.invite(
      ConversationTypeEnum.cobrowse,
      interaction,
      this.ticketId,
      { requestType: this.requestType }
    );
    this.connecting = true;
    this.connectTimeout = setTimeout(() => {
      this.notification.info("Co-browse request timed out", {
        body: "You can either wait a bit longer or send another invitation",
      });
      this.connecting = false;
    }, 10000); // 10 seconds
  }

  copyChatInvitation() {
    const invitation = this.interactionService.getInvitation(
      ConversationTypeEnum.cobrowse,
      this.ticketId,
      { requestType: this.requestType }
    );

    copyTextToClipboard(invitation);
    this.notification.success("Invitation", {
      body: "Copied to clipboard",
    });
  }

  get isChatAvailable() {
    return (
      this.interactionService.getActiveInteraction()?.getType() ===
      ConversationTypeEnum.chat
    );
  }
}
