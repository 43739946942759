import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subject, Subscription } from "rxjs";

import {
  ErrorPageCode,
  PARAM_CONVERSATION_ID,
  PARAM_ROOM_ID,
} from "../../app.enums";
import { debug, trapFocus } from "../../app.utils";
import { IGenesysAgent, Interaction } from "../../models";
import {
  InteractionService,
  InvitationService,
  QRService,
} from "../../services";
import { IConfigOptions, IMessage } from "@auvious/integrations";
import {
  combineLatestWith,
  filter,
  take,
  withLatestFrom,
} from "rxjs/operators";
import {
  ActivityIndicatorService,
  AgentParam,
  AppConfigService,
  ApplicationService,
  AppointmentParticipantStateEnum,
  AuviousRtcService,
  CallEndedAction,
  centerInOut,
  ConferenceService,
  ConferenceStore,
  ConversationOriginEnum,
  CustomerParam,
  debugError,
  DeviceService,
  GenericErrorHandler,
  IApplication,
  IAppointment,
  IAppointmentRouting,
  IConversationEventHandlers,
  IHook,
  IInteraction,
  IRecorderInfo,
  IRecorderOptions,
  IUser,
  LocalMediaService,
  MediaRulesService,
  PointerService,
  PublicParam,
  RatingService,
  RecorderService,
  RecorderStateEnum,
  slideInOut,
  TerminateReasonEnum,
  TicketTypeEnum,
  UserService,
  WindowEventService,
} from "../../../core-ui";
import {
  ConferenceMetadataKeyEnum,
  ConversationDestinationEnum,
  ConversationTypeEnum,
  LayoutEnum,
  OriginModeEnum,
  PARAM_TICKET_ID,
  UserCapabilityEnum,
  UserRoleEnum,
} from "../../../core-ui/core-ui.enums";
import {
  CustomerMetricsMetadata,
  IntegrationMetadata,
  TransferMetadata,
} from "../../../core-ui/models/Metadata";
import { ConfirmNotification } from "../../../core-ui/models/notifications/ConfirmNotification";
import { AnalyticsService } from "../../../core-ui/services/analytics.service";
import { AppointmentService } from "../../../core-ui/services/appointment.service";
import { NotificationService } from "../../../core-ui/services/notification.service";
import { SnapshotService } from "../../../core-ui/services/snapshot.service";
import { ThemeService } from "../../../core-ui/services/theme.service";
import {
  ProtectedTicketService,
  PublicTicketService,
} from "../../../core-ui/services/ticket.service";
import {
  ReadyAction,
  TransferAction,
  windowActionType,
} from "../../../core-ui/services/window.event.service";
import { WIDGET_CUSTOMER_CALL_ACCEPTED_ACK } from "../../app.enums";
import { ConversationService } from "../../services/conversation.service";
import { NgZone } from "@angular/core";
import { InteractionMetricEnum } from "../../../core-ui/core-ui.enums";
import { IEndpointMetadata } from "../../../core-ui/models/IEndpointState";
import moment from "moment";
import { CobrowseService } from "../../../core-ui/services/cobrowse.service";
import { ShareLinkComponent } from "../../../app/components";
import { StreamTypes } from "@auvious/rtc";

@Component({
  selector: "app-page-conference",
  templateUrl: "./conference.component.html",
  styleUrls: ["./conference.component.scss"],
  animations: [slideInOut, centerInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConferencePageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  interaction: IInteraction;
  me: IUser;
  recorderOptions: IRecorderOptions;
  recorderInfo: IRecorderInfo;
  recorderTracked = false;
  activeAgents: IGenesysAgent[] = [];
  QRData: string;
  subscriptions: Subscription;
  handlers: IConversationEventHandlers;
  waitingForCustomerTimeout;
  confirmNotificationCustomerTimeout: ConfirmNotification;
  customerTicket: string;
  appointment: IAppointment<IAppointmentRouting>;

  // flags
  isDeviceSetup: boolean;
  isRating = false;
  isInteractionEnding = false;
  isOriginWidgetWithoutVideo = false;
  isAccepted = false;
  isCustomerEndingConversation = false;
  isAgentEndingConversation = false;
  isReviewingSnapshots = false;
  isAgentConnected = false;
  isCallStarted = false;
  isConversationEndingTracked = false;
  isAgent = false;
  isCustomer = false;
  isGuestAgent = false;
  isConversationEnded = false;
  isMessagingEnabled = false;
  isWhisper = false;
  isFocusedAfterStreamPublished = false;
  isCallEndingHandled = false;

  $callEnding = new Subject();
  $callEnded = new Subject();
  $recorderStarted = new Subject<IRecorderInfo>();

  @ViewChild("shareLinkOptions") shareLinkOptionsRef: ShareLinkComponent;

  constructor(
    private config: AppConfigService,
    private router: Router,
    private translate: TranslateService,
    private errorHandler: GenericErrorHandler,
    private conferenceService: ConferenceService,
    private windowEventService: WindowEventService,
    private recorderService: RecorderService,
    private qrService: QRService,
    private ratingService: RatingService,
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private publicTicketService: PublicTicketService,
    private notification: NotificationService,
    private rtcService: AuviousRtcService,
    private analyticsService: AnalyticsService,
    private interactionService: InteractionService,
    private cdr: ChangeDetectorRef,
    private snapshot: SnapshotService,
    private theme: ThemeService,
    private conversation: ConversationService,
    private appointmentService: AppointmentService,
    public store: ConferenceStore,
    private zone: NgZone,
    private pointerService: PointerService,
    private local: LocalMediaService,
    private invitationService: InvitationService,
    private ticketService: ProtectedTicketService,
    private cobrowseService: CobrowseService,
    private activity: ActivityIndicatorService,
    private device: DeviceService,
    private mediaRules: MediaRulesService,
    private userService: UserService,
    private host: ElementRef,
    private render: Renderer2
  ) {
    this.isDeviceSetup = false;

    this.me = userService.getActiveUser();

    this.isAgent = this.userService.isAgent;
    this.isCustomer = userService.isCustomer;
    this.isGuestAgent = userService.isGuestAgent;
    this.isMessagingEnabled = this.me.hasCapability(
      UserCapabilityEnum.messaging
    );

    this.route.data.subscribe((data) => {
      this.interaction = data.interaction;
    });

    this.subscriptions = new Subscription();

    this.qrService.QRLinkAvailable$.subscribe((QRData) => {
      this.QRData = QRData;
      this.cdr.detectChanges();
    });

    if (this.isAgent) {
      const chatTranscriptHook: IHook = {
        run: async () => this.saveChatTranscript(),
      };
      // in case we terminate the conference with an active recorder
      this.conferenceService.registerPreTerminateHook(chatTranscriptHook);

      //in case the recorder stops during a call
      this.recorderService.registerPreStopHook(chatTranscriptHook);

      this.isAccepted = true;
    } else if (this.isCustomer) {
      this.isAccepted = this.interactionService.hasAcceptedTerms();
      if (this.interactionService.hasToAcceptTerms() && this.isAccepted) {
        this.trySaveAcceptedTerms();
      }
    }
  }

  @HostListener("click")
  click() {
    if (!this.isCallStarted) {
      return;
    }
    // this.shareOpen = false;
    this.cdr.detectChanges();
  }

  async ngOnInit() {
    this.observeCallEnd();

    this.isWhisper = !!this.interaction.getWhisperMode();

    if (this.isAgent) {
      this.isConversationEndingTracked = false;

      this.subscriptions.add(
        this.conferenceService.endpointJoined$
          .pipe(
            filter(
              (e) =>
                !this.isWhisper &&
                e.metadata?.roles?.includes(UserRoleEnum.customer) &&
                this.config.publicParam(PublicParam.DISCLAIMER_SNAPSHOT_ENABLED)
            )
          )
          .subscribe((e) => {
            this.analyticsService.updateInteractionMetrics(this.interaction, {
              [InteractionMetricEnum.termsAcceptedSnapshot]:
                e.metadata?.termsAccepted,
              [InteractionMetricEnum.termsAcceptedAt]:
                e.metadata?.termsAcceptedAt,
            });
          })
      );

      /**
       * Flow that we are covering here:
       * - agent has ended the conversation
       * - the widget got a conversation ended event
       * - customer leaves the conference session with a 'conversationEnded' reason
       * - agent updates the analytics
       *
       * In a Genesys premise setup, if the customer ends the conversation and wraps up the call,
       * the iframe that hosts the app is destroyed, thus leaving no time for the UI to update the analytics
       */
      this.subscriptions.add(
        this.conferenceService.endpointLeft$
          .pipe(
            filter(
              (p) =>
                !this.isWhisper &&
                p?.metadata?.roles?.includes(UserRoleEnum.customer) &&
                p?.metadata?.origin === ConversationOriginEnum.WIDGET &&
                p?.metadata?.reason === TerminateReasonEnum.conversationEnded
            )
          )
          .subscribe((p) => {
            this.analyticsService.trackCallEnded(this.interaction, true);
          })
      );

      this.subscriptions.add(
        this.snapshot.reviewing$.subscribe((e) => {
          this.isReviewingSnapshots = true;
        })
      );

      // when we are coming from a transfer, the call is on hold. Take it out
      this.subscriptions.add(
        this.conferenceService.callHoldStateChange$
          .pipe(
            filter(
              (c) => this.interaction.isTransfer() && this.isAgent && c.isOnHold
            ),
            take(1)
          )
          .subscribe((c) => {
            this.conferenceService.setConferenceMetadata(
              new TransferMetadata(this.conferenceService.myself, false)
            );
            this.conferenceService.toggleCallHold();
          })
      );

      this.subscriptions.add(
        this.conferenceService.endpointMetadataChanged$
          .pipe(
            filter(
              (e) =>
                !this.isWhisper &&
                !!(e?.newMetadata as IEndpointMetadata)?.customerMetadata
            )
          )
          .subscribe((e) => {
            const customerMetadata = (e.newMetadata as IEndpointMetadata)
              .customerMetadata;
            this.analyticsService.updateInteractionMetrics(this.interaction, {
              [InteractionMetricEnum.customerMetadata]: customerMetadata,
            });
          })
      );

      /*
      this.subscriptions.add(
        this.conferenceService.getConferenceMetadataSet$
          .pipe(filter((m) => m instanceof CustomerMetricsMetadata))
          .subscribe((meta: CustomerMetricsMetadata) => {
            // if we have metadata and we the customer has marked when he got the ticket,
            // cancel the timer countdown that was set to terminate the call if no customer joined.
            if (!!meta.ticketReceivedAt() && this.waitingForCustomerTimeout) {
              clearTimeout(this.waitingForCustomerTimeout);
              this.waitingForCustomerTimeout = null;
            }
            if (!!this.confirmNotificationCustomerTimeout) {
              this.notification.dismiss(
                this.confirmNotificationCustomerTimeout
              );
              this.confirmNotificationCustomerTimeout = null;
            }
          })
      ); */

      this.recorderOptions = {
        conferenceId: this.interaction.getRoom(),
        conversationId: this.interaction.getId(),
        applicationId: this.application.getId(),
        audio: true,
        video: !this.config.agentParamEnabled(AgentParam.RECORDER_AUDIO_ONLY),
      };

      this.subscriptions.add(
        combineLatest([this.store.updated$, this.$recorderStarted])
          .pipe(
            filter(
              () =>
                this.store.streams.length > 0 &&
                this.recorderInfo?.state === RecorderStateEnum.active &&
                !this.recorderTracked
            )
          )
          .subscribe(() => {
            this.recorderTracked = true;
            this.analyticsService.trackRecordingStarted(
              this.recorderInfo,
              this.interaction
            );
          })
      );

      if (!this.isGuestAgent) {
        this.renewCustomerLink();
      }

      this.handlers = {
        ending: (conversationId) => {
          debug("conversation ending");
          if (this.interaction?.getId() === conversationId) {
            // mark that the actual interaction has ended but we are still in the room
            // so that we can clear it once we leave
            this.isInteractionEnding = true;
            if (
              !this.isAgentEndingConversation &&
              !this.isReviewingSnapshots &&
              !this.isRating
            ) {
              this.conferenceService.confirmTerminateConferenceRequest();
            }
            if (!this.isConversationEndingTracked && !this.isWhisper) {
              this.analyticsService.trackCallEnding(this.interaction);
              this.isConversationEndingTracked = true;
            }
          }
        },
        ended: (conversationId) => {
          debug("conversation ended from notification");
          if (
            (!this.interaction || // coming from iFrame refresh. not a real world scenario
              this.interaction?.getId() === conversationId) &&
            !this.isReviewingSnapshots
          ) {
            if (!this.isAgentEndingConversation) {
              this.conferenceService.terminateConferenceRequest(
                TerminateReasonEnum.conversationEnded
              );
            }
            this.interactionService.clearActiveInteraction();
          }
        },
        transferring: async (conversationId) => {
          debug("conversation is being transferred");
          if (this.interaction?.getId() === conversationId) {
            this.isInteractionEnding = true;

            try {
              await this.conferenceService.setConferenceMetadata(
                new TransferMetadata(this.conferenceService.myself, true)
              );
            } catch (ex) {
              debugError(ex);
              this.errorHandler.handleError(ex);
            }
            if (!this.isWhisper)
              this.analyticsService.trackCallTransferred(this.interaction);

            // stop recording, new agent will start a new one, as he must be the new owner and update
            // conference metadata
            if (!!this.recorderInfo) {
              try {
                // race condition, sometimes the metadata was not removed because the recorder event did not reach on time
                this.conferenceService.removeConferenceMetadata(
                  ConferenceMetadataKeyEnum.recorder
                );
                await this.recorderService.stop(
                  this.recorderInfo.recorderId,
                  this.recorderInfo.conversationId,
                  this.recorderInfo.instanceId
                );
              } catch (ex) {
                debugError(ex);
                this.errorHandler.handleError(ex);
              }
            }

            // if I am pointing, stop
            if (
              this.pointerService.isStarted &&
              this.pointerService.isRequester(
                this.conferenceService.myself.endpoint
              )
            ) {
              this.pointerService.end(this.conferenceService.myself.endpoint);
            }

            // if I'm screen sharing, stop
            this.tryStopMyScreenShare();

            // handleCallEnded will terminate an active co-browse session but since the session is already terminated
            // at that point, the conference metadata are not updated
            if (this.cobrowseService.isStarted) {
              await this.conferenceService.removeConferenceMetadata(
                ConferenceMetadataKeyEnum.coBrowse
              );
            }

            // put the call on hold until the new agent joins
            try {
              if (!this.conferenceService.isConferenceOnHold) {
                await this.conferenceService.toggleCallHold();
              }
            } catch (ex) {
              debugError(ex);
              this.errorHandler.handleError(ex);
            }

            this.callEnding({ terminated: false, terminatedRemotely: false });

            //leave the session
            try {
              await this.conferenceService.leaveSession(
                TerminateReasonEnum.transfer
              );
            } catch (ex) {
              debugError(ex);
              this.errorHandler.handleError(ex);
            }
            this.interactionService.clearActiveInteraction();
          }
        },
        transferred: (conversationId) => {
          debug("conversation transferred");
        },
      };
      this.interactionService.registerConversationEventHandlers(this.handlers);
      this.cdr.detectChanges();
    }

    if (this.isCustomer) {
      this.subscriptions.add(
        this.conferenceService.endpointJoined$
          .pipe(filter((e) => e.metadata?.roles?.includes(UserRoleEnum.agent)))
          .subscribe((e) => {
            this.isAgentConnected = true;
            this.cdr.detectChanges();
          })
      );
    }

    if (this.isOriginAppointment) {
      this.subscriptions.add(
        this.appointmentService.appointmentChanged$
          .pipe(filter((a) => !!a))
          .subscribe((a) => (this.appointment = a))
      );
    }

    this.tryConnectToAppointmentConversation();

    if (this.isChildWindow) {
      this.windowEventService
        .init(this.interaction.getParentFrameUrl())
        .subscribe((action) => {
          switch (action.type) {
            case windowActionType.THEME_READY:
              this.theme.setCSSVariables(action.payload);
              break;
            case windowActionType.CUSTOMER_METADATA_READY:
              if (this.mediaRules.canSaveCustomerMetadata) {
                this.conferenceService.updateEndpointMetadata({
                  customerMetadata: MediaRulesService.filterCustomerMetadata(
                    this.config.publicParam(
                      PublicParam.CUSTOMER_METADATA_BLACKLIST
                    ),
                    action.payload
                  ),
                });
              }
              break;
            case windowActionType.CALL_QUALITY_OPTIONS:
              this.local.setCallQualityOptions(action.payload);
              break;
          }
        });
      this.windowEventService.sendMessage(new ReadyAction());

      this.subscriptions.add(
        this.conferenceService.conferenceMetadataSet$
          .pipe(filter((m) => m instanceof TransferMetadata && m.on))
          .subscribe((m) => {
            this.windowEventService.sendMessage(new TransferAction());
            this.tryStopMyScreenShare();
          })
      );
    }

    if (this.isWidget) {
      this.conferenceService.localStreamPublished$
        .pipe(
          filter(
            (s) =>
              !!s &&
              s.type !== StreamTypes.SCREEN &&
              !this.isFocusedAfterStreamPublished
          )
        )
        .subscribe((s) => {
          this.host.nativeElement.focus();
          if (this.interaction.getOriginMode() === OriginModeEnum.kiosk) {
            trapFocus(this.host.nativeElement);
          }
          this.isFocusedAfterStreamPublished = true;
        });
    }
  }

  ngAfterViewInit(): void {
    // in kiosk mode, the tab navigation also focuses on elements that are behind the video call. We need to trap the focus
    // if (this.interaction.getOriginMode() === OriginModeEnum.kiosk) {
    if (this.isWidget) {
      this.render.setAttribute(this.host.nativeElement, "tabindex", "0");
      this.render.setAttribute(this.host.nativeElement, "role", "group");
      this.host.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.handlers = null;
    this.subscriptions.unsubscribe();
    if (this.isOriginAppointment) {
      this.appointmentService.notifyChange(undefined);
    }
    if (this.waitingForCustomerTimeout) {
      clearTimeout(this.waitingForCustomerTimeout);
      this.waitingForCustomerTimeout = null;
    }
  }

  private observeCallEnd() {
    this.subscriptions.add(
      this.$callEnding
        .pipe(combineLatestWith(this.$callEnded))
        .subscribe((e) => {
          this.activity.loading(false);
          this.goBack();
        })
    );
  }

  private tryStopMyScreenShare() {
    if (
      !!this.store.screenStream &&
      this.store.screenStream.originator.endpoint ===
        this.conferenceService.myself.endpoint
    ) {
      this.local.closeScreenStream();
    }
  }

  private async tryConnectToAppointmentConversation() {
    if (!this.isOriginAppointment) {
      return;
    }

    if (!this.appointment) {
      this.appointment = await this.appointmentService.get(
        this.interaction.getAppointmentId()
      );
    }
    try {
      // update appointment state for both agents and customers
      this.appointmentService.updateState(
        this.interaction.getAppointmentId(),
        "inprogress"
      );

      // only initialize conversation for customers
      if (!this.isCustomer) {
        return;
      }

      const ticket = await this.publicTicketService.getTicket(
        this.route.snapshot.paramMap.get(PARAM_TICKET_ID)
      );

      if (!this.conversation.isConversationInitialised) {
        const config: IConfigOptions =
          this.conversation.prepareConversationConfig(ticket, this.appointment);

        await this.conversation.init(config);
      }
    } catch (ex) {
      // could not load appointment conversation..
    }

    this.subscriptions.add(
      // if conversation was ended by agent, leave the room
      this.conversation.ended$
        // ended$ event comes even if we as a customer end the conversation once we hit the hangup button
        // it also comes if agent ends the conversation
        .pipe(filter((_) => !this.isCustomerEndingConversation))
        .subscribe((_) => {
          this.conferenceService.terminateConferenceRequest(
            TerminateReasonEnum.leave
          );
          // we are about to leave as customers, update our state
          this.appointmentService.updateParticipantState(
            this.interaction.getAppointmentId(),
            this.appointment.participantIds.find((p) => p.type === "CUSTOMER")
              .id,
            AppointmentParticipantStateEnum.LEFT
          );
        })
    );
  }

  closeQRPanel() {
    this.QRData = null;
  }

  agentSelected() {
    this.notification.show("now what?");
  }

  callStarted() {
    this.isCallStarted = true;
    // if the rating popup is visible, hide the controls.
    this.subscriptions.add(
      this.ratingService.popupVisibility$.subscribe((visible) => {
        this.isRating = visible;
      })
    );

    if (this.isCustomer && this.isWidget) {
      const metrics = new CustomerMetricsMetadata(
        this.conferenceService.myself,
        {
          // ticketReceivedAt: no need to use this. we rely on the getMessages flow of the timeout
          joinedAt: new Date(),
        }
      );
      this.conferenceService.setConferenceMetadata(metrics);
    }
    if (
      this.isAgent &&
      (this.application.supportsInteractions() ||
        // we need this for genesys premise
        this.applicationService.isStandaloneApp)
    ) {
      // set the integration application id so that it can be retrieved by the customer
      this.conferenceService.setConferenceMetadata(
        new IntegrationMetadata(
          this.conferenceService.myself,
          this.interaction.getId()
        )
      );
    }
  }

  callPendingRating(isRating: boolean) {
    this.QRData = null;
    if (isRating) {
      this.endConversation(TerminateReasonEnum.leave);
    }
  }

  async callEnded(payload: { reason: TerminateReasonEnum; error?: any }) {
    if (!!payload.error) {
      switch (payload.error.status) {
        case 404:
          this.router.navigate(["/error", ErrorPageCode.ROOM_NOT_FOUND], {
            queryParamsHandling: "preserve",
          });
          break;
        case 403:
          if (payload.error.data.error === "participant limit reached") {
            this.router.navigate(
              ["/error", ErrorPageCode.PARTICIPANT_LIMIT_REACHED],
              { queryParamsHandling: "preserve" }
            );
          } else {
            if (
              window.confirm(
                this.translate.instant("call-ended-error-confirm-reload")
              )
            ) {
              window.location.reload();
              return;
            }
          }
          break;
        default:
          if (
            window.confirm(
              this.translate.instant("call-ended-error-confirm-reload")
            )
          ) {
            window.location.reload();
            return;
          }
          break;
      }
    } else {
      await this.endConversation(payload.reason);

      if (this.isInteractionEnding) {
        this.interactionService.clearActiveInteraction();
      }
      this.activity.loading(true);
      this.$callEnded.next(null);
    }
  }

  async callEnding(payload: {
    terminated: boolean;
    terminatedRemotely: boolean;
  }) {
    // stop from firing twice
    if (this.isCallEndingHandled) {
      return;
    }
    this.isCallEndingHandled = true;
    if (!this.isWhisper && this.isAgent && !payload.terminatedRemotely) {
      if (payload.terminated) {
        // keep for callback, even though we terminate the call, we must:
        // 1. tell the agent the room is closed
        // 2. in analytics, keep the room id
        if (this.interaction.getType() !== ConversationTypeEnum.callback) {
          this.interaction.setRoom(null);
        }
        if (!this.isInteractionEnding) {
          // if interaction is ending, trackCallEnding will be called at that event
          this.analyticsService.trackCallEnding(this.interaction);
          this.interactionService.setActiveInteraction(this.interaction);
        }
      }
      if (
        !(
          this.conferenceService.getConferenceMetadata(
            ConferenceMetadataKeyEnum.transfer
          ) as TransferMetadata
        )?.on
      ) {
        // we need to have enought time to mark the interaction as ended (remove room) if in popup (window will close)
        // otherwise we may end up in a terminated room but not updated interaction with null room
        // this also makes it slow for normal calls so do not wait if not in popup.
        this.device.isPopUp
          ? await this.analyticsService.trackCallEnded(this.interaction)
          : this.analyticsService.trackCallEnded(this.interaction);
      }
    }
    this.$callEnding.next(null);
  }

  goBack() {
    // sometimes we get out of the zone
    this.zone.run(() => {
      this.activity.loading(false);
      if (this.shouldBeAbleToCreateRoomAgain()) {
        this.router.navigate(["/welcome"], {
          queryParams: { [PARAM_ROOM_ID]: null, [PARAM_CONVERSATION_ID]: null },
          queryParamsHandling: "merge",
        });
      } else {
        this.rtcService.unregister().then(() => this.rtcService.logout());
        this.router.navigate(
          [
            "/thank-you",
            this.isCustomer
              ? UserRoleEnum.customer.toLowerCase()
              : UserRoleEnum.agent.toLowerCase(),
          ],
          { queryParamsHandling: "preserve" }
        );
      }
    });
  }

  private async endConversation(reason: TerminateReasonEnum) {
    if (!this.isOriginAppointment || this.isConversationEnded) {
      return;
    }

    if (this.getAppointmentParticipantId()) {
      try {
        // update participant state
        await this.appointmentService.updateParticipantState(
          this.interaction.getAppointmentId(),
          this.getAppointmentParticipantId(),
          AppointmentParticipantStateEnum.LEFT
        );
      } catch (ex) {
        this.errorHandler.handleError(ex);
      }
    }

    if (this.isAgent) {
      // update appointment to state completed
      this.appointmentService.updateState(
        this.interaction.getAppointmentId(),
        "end"
      );
    }

    this.isConversationEnded = true;
    this.isCustomerEndingConversation = true;
    this.conversation.endConversation();
  }

  private shouldBeAbleToCreateRoomAgain(): boolean {
    return this.application.isReady() && this.isAgent && !this.isGuestAgent;
  }

  async exit() {
    if (this.isChildWindow) {
      this.windowEventService.sendMessage(
        new CallEndedAction({ reason: TerminateReasonEnum.leave })
      );
    }
    if (!this.isWhisper && this.isAgent) {
      await this.analyticsService.trackCallEnded(this.interaction);
    }
    this.goBack();
  }

  /**
   * fires once the user has joined the call and is present in the room.
   */
  deviceSetupDoneAndRoomJoined() {
    this.isDeviceSetup = true;
    if (
      this.isAgent &&
      !this.isGuestAgent &&
      !this.isOriginCallback &&
      !this.isOriginAppointment
    ) {
      if (
        (this.isOriginWidget && !this.interaction.isCustomerInvited()) ||
        // if we auto-invite customer, check if we actually have an interaction
        // (not origin=embed and not start calls without interaction)
        (this.config.agentParamEnabled(AgentParam.AUTO_INVITE_CUSTOMER) &&
          !(
            this.isOriginEmbedded &&
            !this.config.agentParamEnabled(
              AgentParam.VIDEO_CALL_REQUIRES_INTERACTION
            )
          ))
      ) {
        // send link to widget as notice
        this.inviteCustomer();
        if (this.interaction.getType() !== ConversationTypeEnum.chat) {
          // set a timeout to wait for customer to join, to avoid zombie conversations
          this.waitForCustomerToJoin();
        }
      } else if (this.isOriginAppointment) {
        this.waitForCustomerToJoin();
      }
    }

    if (this.isOriginAppointment && !!this.getAppointmentParticipantId()) {
      this.appointmentService.updateParticipantState(
        this.interaction.getAppointmentId(),
        this.getAppointmentParticipantId(),
        AppointmentParticipantStateEnum.JOINED
      );
    }
  }

  private getAppointmentParticipantId() {
    return this.appointment.participantIds.find(
      (p) => p.type === (this.isCustomer ? "CUSTOMER" : "AGENT")
    )?.id;
  }

  private async inviteCustomer() {
    try {
      await this.interactionService.invite(
        this.interaction.getType(),
        this.interaction,
        this.customerTicket
      );
      if (!this.isWhisper) {
        this.analyticsService.trackInvitationSent(this.interaction);
      }
      this.renewCustomerLink();
    } catch (ex) {
      this.notification.warn("Unable to send invitation to customer.", {
        ttl: -1,
        body: "Please share the room link with the customer by manually copying it and pasting it to the active conversation.",
      });
      this.shareLinkOptionsRef?.open();
    }
  }

  protected async renewCustomerLink() {
    try {
      const ticketRequest = this.invitationService.prepareTicketRequest(
        this.config.agentParamEnabled(AgentParam.SINGLE_USE_TICKET_ENABLED)
          ? TicketTypeEnum.SingleUseTicket
          : TicketTypeEnum.MultiUseTicket,
        this.config.agentParam(AgentParam.TICKET_LENGTH) ?? 6,
        this.interaction.getRoom(),
        this.interaction.getCustomerId(),
        this.interaction.getId(),
        this.interaction.getCustomerName()
      );
      this.customerTicket = await this.ticketService.createTicket(
        ticketRequest
      );
    } catch (ex) {
      this.errorHandler.handleError(ex);
      this.notification.error("Oups!", {
        body: "Could not create shareable link",
      });
    }
  }

  viewChange(layout: LayoutEnum) {
    // nothing for now
  }

  async saveChatTranscript(): Promise<void> {
    try {
      if (
        !this.recorderInfo ||
        this.recorderInfo.state !== RecorderStateEnum.active
      ) {
        return;
      }

      const transcript = await this.interactionService.getChatTranscript(
        this.interaction.getId(),
        this.interaction.getChannel(),
        false
      );

      if (!transcript || transcript?.chatTranscriptList?.length === 0) {
        return;
      }

      transcript.instanceId = this.recorderInfo.instanceId;
      transcript.recorderId = this.recorderInfo.recorderId;

      await this.recorderService.addTranscript(transcript);
    } catch (ex) {
      debugError(ex);
    }
  }

  remotePopupClosed() {
    // this.shareOpen = true;
    this.notification.show("Auvious", {
      body: this.translate.instant(
        "The customer left the video call. You can send a new invitation."
      ),
    });
    this.isOriginWidgetWithoutVideo = true;
  }

  recorderStarted(recorder: IRecorderInfo) {
    if (!!recorder && !this.isWhisper) {
      this.recorderInfo = recorder;
      this.$recorderStarted.next(recorder);
    }
  }

  async recorderStopped(recorder: IRecorderInfo) {
    try {
      this.recorderInfo = recorder;
      const session = await this.recorderService.getSessionInfo(
        recorder.recorderId,
        recorder.conversationId,
        recorder.instanceId
      );
      if (!!session && !this.isWhisper) {
        this.analyticsService.trackRecordingStopped(session, this.interaction);
      }
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
  }

  termsAccepted() {
    this.isAccepted = true;
    this.interactionService.acceptTerms();
    this.trySaveAcceptedTerms();
  }

  async termsRejected() {
    if (this.isOriginAppointment) {
      await this.conversation.sendMessage(
        this.translate.instant("Consent not given"),
        "notice"
      );
      this.endConversation(TerminateReasonEnum.termsRejected);
    }
    this.exit();
  }

  trySaveAcceptedTerms() {
    if (this.config.publicParam(PublicParam.DISCLAIMER_SNAPSHOT_ENABLED)) {
      this.interactionService.saveAcceptedTerms(
        this.config.customerParam(CustomerParam.DISCLAIMER_TEXT)
      );
    }
  }

  waitForCustomerToJoin() {
    const timeoutSeconds =
      this.config.agentParam(AgentParam.DISCONNECTED_CUSTOMER_TIMEOUT) * 1000; // it is in seconds
    if (!timeoutSeconds) {
      return;
    }
    this.waitingForCustomerTimeout = setTimeout(async () => {
      // 1. get a history of messages and try to find the ACK message
      try {
        this.waitingForCustomerTimeout = null;
        const messages: IMessage[] = await this.conversation.getMessages(
          this.interaction
        );
        const found = messages
          ?.filter((m) => moment(m.timestamp).isSame(new Date(), "day"))
          .find(
            (m) => m.text && m.text.includes(WIDGET_CUSTOMER_CALL_ACCEPTED_ACK)
          );
        if (!!found) {
          return;
        }
        this.confirmNotificationCustomerTimeout = new ConfirmNotification(
          "Customer appears to have left the conversation",
          "Would you like to end the conversation and the call?"
        );
        this.confirmNotificationCustomerTimeout.onConfirmed(async () => {
          try {
            // end conversation
            this.isAgentEndingConversation = true;
            await this.conversation.leaveConversation(this.interaction);
          } catch (ex) {
            // nothing
          }
          //end call
          this.conferenceService.terminateConferenceRequest(
            TerminateReasonEnum.customerDisconnected
          );
        });

        this.notification.notify(this.confirmNotificationCustomerTimeout);
      } catch (ex) {
        this.errorHandler.handleError(ex);
      }
    }, timeoutSeconds);
  }

  /** view getters */

  get isCallbackWaitingVisible() {
    return (
      (this.isOriginCallback || this.isOriginAppointment) &&
      this.isCustomer &&
      !this.isAgentConnected &&
      this.isDeviceSetup &&
      !this.isRating
    );
  }

  get participantId() {
    return this.me.getId();
  }

  get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  get isOriginWidget() {
    return this.interaction.getOrigin() === ConversationOriginEnum.WIDGET;
  }

  get isOriginEmbedded() {
    return this.interaction.getOrigin() === ConversationOriginEnum.EMBEDDED;
  }

  get isOriginAppointment() {
    return this.interaction.getOrigin() === ConversationOriginEnum.APPOINTMENT;
  }

  get isOriginPopup() {
    return this.interaction.getOrigin() === ConversationOriginEnum.POPUP;
  }

  get isOriginCallback() {
    return this.interaction.getType() === ConversationTypeEnum.callback;
  }

  get isDestinationMobileOffice() {
    return (
      this.interaction.getDestination() ===
      ConversationDestinationEnum.MOBILE_OFFICE
    );
  }

  get isWidget() {
    return DeviceService.isIFrame && !this.isAgent && this.isOriginWidget;
  }

  get isChildWindow() {
    return !this.isAgent && (this.isOriginWidget || this.isOriginPopup);
  }

  get isTrustedConversationDestination() {
    return [ConversationDestinationEnum.MOBILE_OFFICE].includes(
      this.interaction.getDestination()
    );
  }

  get isMessagingAvailable() {
    return (
      this.isMessagingEnabled &&
      this.isCustomer &&
      this.isDeviceSetup &&
      !this.isRating &&
      !this.snapshot.isStarted
    );
  }

  get shouldShowShareOptions() {
    return (
      this.isAgent &&
      !this.isRating &&
      this.isDeviceSetup &&
      (!this.isGuestAgent || this.isTrustedConversationDestination) &&
      (this.mediaRules.isAgentInvitationAvailable ||
        this.mediaRules.isCustomerInvitationAvailalbe)
    );
  }
}
