import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { IFilterSelection } from "../../../../../core-ui/models/interfaces";
import { AgentParam, AppConfigService } from "../../../../../core-ui";

@Component({
  selector: "app-duration-filter",
  templateUrl: "./duration-filter.component.html",
  styleUrls: ["./duration-filter.component.scss"],
})
export class DurationFilterComponent implements OnInit {
  @Output() changed: EventEmitter<string[]> = new EventEmitter();

  durations = [];
  selection: string[] = [];

  constructor(private config: AppConfigService) {}

  ngOnInit(): void {
    this.durations = this.config
      .agentParam(AgentParam.APPOINTMENT_DURATION_OPTIONS)
      .sort((a, b) => a - b);
  }

  selectionChanged(filter: IFilterSelection<moment.Duration>) {
    this.selection = filter.checked
      ? [...this.selection, filter.value.toISOString()]
      : this.selection.filter((s) => s !== filter.value.toISOString());
    this.changed.emit(this.selection);
  }
}
