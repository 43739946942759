<div class="tile-info-group" *ngIf="isGroupOneAvailable()">
  <div class="tile-info" *ngIf="isDebugRtc">
    <av-tooltip position="bottom">
      rtc
      <div class="debug" tooltipMessage>
        <div>id: {{ stream.id }}</div>
        <div>username: {{ stream.originator.username }}</div>
        <div>my username: {{ myself.username }}</div>
        <div>endpoint: {{ stream.originator.endpoint }}</div>
        <div *ngIf="!!stream.mediaStream">
          media stream: {{ stream.mediaStream.id }}
        </div>
        <div>local: {{ isLocal }}</div>
        <div>type: {{ stream.type }}</div>
        <!-- <div>cam off: {{isMutedStream(stream, kindVideo)}}</div> -->
        <!-- <div>mic off: {{isMutedStream(stream, kindAudio)}}</div> -->
        <div>
          tag muted: {{ stream.originator.endpoint === myself.endpoint }}
        </div>
      </div>
    </av-tooltip>
  </div>

  <ng-container *ngIf="!isScreen">
    <div
      [attr.aria-label]="'Participant is agent' | translate"
      class="tile-info"
      [class.hidden]="isHidden"
      *ngIf="isRoleAgentVisible"
      data-tid="tile-info/role-agent"
    >
      <av-tooltip position="bottom" message="AGENT">
        <av-icon class="tile-icon" mode="light" name="headset"></av-icon>
      </av-tooltip>
    </div>

    <div
      class="tile-info"
      [class.hidden]="isHidden"
      *ngIf="isNetworkIndicatorVisible"
    >
      <app-network-indicator
        [endpoint]="stream.originator.endpoint"
      ></app-network-indicator>
    </div>

    <span
      [attr.aria-label]="'Participant\'s microphone is muted' | translate"
      role="alert"
      *ngIf="isMutedMicAria() && !isLocal"
    >
    </span>

    <span
      [attr.aria-label]="'Participant\'s camera is muted' | translate"
      role="alert"
      *ngIf="isMutedCam() && !isLocal"
    >
    </span>

    <div
      class="tile-info"
      [attr.aria-label]="'Participant\'s microphone is muted' | translate"
      *ngIf="isMutedMic()"
      data-tid="tile-info/mic-muted"
    >
      <av-icon class="tile-icon" mode="light" name="mic-mute"></av-icon>
    </div>

    <div
      class="tile-info"
      [attr.aria-label]="'Participant has enabled closed captions' | translate"
      [class.hidden]="isHidden"
      *ngIf="isCaptionsOn"
      data-tid="tile-info/captions-on"
    >
      <av-icon class="tile-icon" mode="light" name="cc"></av-icon>
    </div>

    <div
      class="tile-info"
      [attr.aria-label]="'Participant\'s marker color' | translate"
      *ngIf="color() && isPointerActive"
      data-tid="tile-info/pointer-color"
    >
      <app-point-color [color]="color()"></app-point-color>
    </div>

    <div
      [attr.aria-label]="
        ('Participant\'s language is ' | translate) + participantLanguage
      "
      class="tile-info tile-info-lang"
      [class.hidden]="isHidden"
      *ngIf="isParticipantLanguageEnabled"
      data-tid="tile-info/user-language"
    >
      <av-tooltip position="bottom">
        {{ participantLanguage }}
        <ng-container tooltipMessage>
          {{ "Customer's language is" | translate }}
          {{ participantLanguage | translate }}
        </ng-container>
      </av-tooltip>
    </div>
  </ng-container>

  <div role="group" class="tile-info" *ngIf="isViewerDisabled">
    <av-tooltip position="bottom">
      <div class="fx-row fx-align-center">
        <div class="warning">!</div>
        <small
          class="warning-label tile-info-tiny-hidden tile-info-pointer-hidden"
          translate
          >Poor Connection</small
        >
      </div>
      <ng-container tooltipMessage>
        <small [innerHTML]="'The video will resume automatically' | translate">
        </small>
      </ng-container>
    </av-tooltip>
  </div>
</div>
<!-- </ng-container> -->

<ng-container *ngIf="!isScreen && snapshotCount > 0">
  <div
    class="tile-info-group"
    [attr.aria-label]="
      ('Number of snapshots taken is ' | translate) + snapshotCount
    "
    [class.hidden]="isHidden"
    data-tid="tile-info/snapshot-count"
  >
    <av-tooltip position="bottom">
      <div
        (click)="snapshotRequest()"
        class="tile-info"
        [class.tile-info-action]="!isWhisper"
      >
        <!-- {{snapshotCount}} &times; -->
        <av-icon
          class="tile-icon tile-icon-snapshot"
          mode="light"
          name="camera"
        ></av-icon>
      </div>

      <ng-container tooltipMessage *ngIf="snapshotCount === 1">
        {{ "snapshot taken" | translate }}
      </ng-container>
      <ng-container tooltipMessage *ngIf="snapshotCount > 1">
        {{ "snapshots taken" | translate }}
      </ng-container>
    </av-tooltip>
  </div>
</ng-container>
